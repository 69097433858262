import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [ "submitButton", "products" ]

  addDeleteToEmptyItems() {
    this.productsTargets.forEach((el) => {
      let num = parseInt(el.value)
      if (isNaN(num) || num == 0) {
        let deleteEl = el.dataset.deleteId
        if (deleteEl) {
          document.getElementById(deleteEl).value = 1
        }
      }
    })
  }

  submit(event) {
    let total_products = 0
    this.productsTargets.forEach((el) => {
      let num = parseInt(el.value)
      if (!isNaN(num)) {
        total_products += num
      }
    })
    if (total_products == 0) {
      event.preventDefault()
    }
    else {
      this.addDeleteToEmptyItems()
      this.submitButtonTarget.classList.add("is-loading")
    }
  }
}
