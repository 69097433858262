import { Controller } from "@hotwired/stimulus"
import axios from "axios"

const HEARTSTATUS = ["❤️", "💛", "💚"]
const SPINNER_DIV_HTML = '<div class="loader is-loading"></div>'

export default class extends Controller {
  static targets = [ "statusHeart" ]
  static values = { url: String, status: Number, payed: Boolean }

  showSpinner1() {
    let statusHtml = SPINNER_DIV_HTML
    if (this.payedValue) {
      statusHtml += "💰"
    }
    this.statusHeartTarget.innerHTML = statusHtml
  }

  showSpinner2() {
    this.statusHeartTarget.innerHTML = HEARTSTATUS[this.statusValue] + SPINNER_DIV_HTML
  }

  showStatus() {
    let statusHtml = HEARTSTATUS[this.statusValue]
    if (this.payedValue) {
      statusHtml += "<br />💰"
    }
    this.statusHeartTarget.innerHTML = statusHtml
  }
  payedValueChanged() {
    this.showStatus()
  }

  statusValueChanged() {
    this.showStatus()
  }

  updateProductStock(data) {
    data.forEach((prod) => {
      let el = document.getElementById(`product-stock-${prod.id}`)
      el.textContent = prod.stock
      el = document.getElementById(`product-ordered-${prod.id}`)
      el.textContent = prod.ordered
      el = document.getElementById(`product-available-${prod.id}`)
      el.textContent = prod.available
    })
  }

  updateStatus() {
    let currentStatus = this.statusValue
    if (currentStatus == 2) {
      return
    }
    let newStatus = (currentStatus + 1)
    this.showSpinner1()
    axios.patch(this.urlValue, { status: newStatus }).then(response => {
      this.statusValue = newStatus
      if (newStatus == 2) {
        axios.get("/orders").then(response => {
          this.updateProductStock(response.data)
        }, error => {
          console.log("Failed to get updated stock")
        });
      }
    }, error => {
      this.showStatus()
    });
  }

  updatePayed() {
    this.showSpinner2()
    axios.patch(this.urlValue, { payed: !this.payedValue }).then(response => {
      this.payedValue = !this.payedValue
    }, error => {
      console.log("Failed to update payed status")
    });
  }
}
